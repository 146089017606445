












































































































import mixins from 'vue-typed-mixins'
import DialogMixins from '@/components/dialogs/mixins/DialogMixins'
import BookingEventActions from '@/views/components/mixins/BookingEventActions'
import Product from '@/calendesk/models/DTO/Response/Product'
import BookingEvent from '@/calendesk/models/BookingEvent'
import log from '@/calendesk/prototypes/log'
import LocationItem from '@/components/LocationItem.vue'
import CLocations from '@/components/CLocations.vue'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'

export default mixins(DialogMixins).extend({
  name: 'BookingFormDialogEventsView',
  components: { CLocations, LocationItem },
  mixins: [BookingEventActions],
  props: {
    events: {
      type: Array,
      default: () => Array<BookingEvent>()
    },
    hideLocations: {
      type: Boolean,
      default: false
    },
    locationAlwaysSelected: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    eventsSumPrice (): number {
      let price = 0

      if (this.events) {
        this.events.forEach((event) => {
          price += (event as BookingEvent).getPrice()
        })
      }

      return price
    }
  },
  mounted () {
    this.$nextTick(() => {
      const actionButtons = document.querySelectorAll('.action-button')
      actionButtons.forEach(button => {
        button.addEventListener('click', () => {
          const action = button.getAttribute('data-action')

          if (action === 'login') {
            this.openLoginDialog()
          } else if (action === 'signup') {
            this.openSignupDialog()
          }
        })
      })
    })
  },
  methods: {
    locationSelectionChange (location: ServiceLocation, item: BookingEvent) {
      this.$emit('locationSelectionChange', {
        location,
        item
      })
    },
    productSelectionChange (isSelected: boolean, item: BookingEvent, product: Product) {
      this.$emit('productSelectionChange', {
        isSelected,
        item,
        product
      })
    },
    isProductSelected (item: BookingEvent, product: Product): boolean {
      return !!item.selectedProducts.find(i => i.id === product.id)
    },
    parseUpsellingDescription (item: BookingEvent): string | null {
      let description = item.service.upsellingDescription

      if (description) {
        try {
          description = this.parseConditions(description)
          return this.parseButtons(description)
        } catch (error) {
          log.error('Upselling description parsing error:', { description, error })

          return description
        }
      }

      return null
    },
    parseButtons (description: string): string {
      const actionButtonRegex = /\[BUTTON_ACTION=({.*?})]/g
      const customButtonRegex = /\[CUSTOM_BUTTON=({.*?})]/g

      const replaceWithButtonHTML = (match: string, p1: string, isCustomButton = false) => {
        const { text = 'Action', action = 'login', url = '#', newTab = false, outline = false, color = 'primary', size = 'default', additionalClass = null } = JSON.parse(p1)
        let buttonClass = `ma-2 action-button v-btn ${this.$vuetify.theme.dark ? 'theme--dark' : 'theme--light'} v-size--${size}`

        if (outline) {
          buttonClass += ` v-btn--outlined ${color}--text`
        } else {
          buttonClass += ` ${color}`
        }

        if (additionalClass) {
          buttonClass += ' ' + additionalClass
        }

        const buttonHTML = `
        <button type="button" class="${buttonClass}" ${isCustomButton ? '' : `data-action="${action}"`}>
          <span class="v-btn__content"> ${text} </span>
        </button>`

        return isCustomButton ? `<a href="${url}" target="${newTab ? '_blank' : '_self'}">${buttonHTML}</a>` : buttonHTML
      }

      // Replace action button
      description = description.replace(actionButtonRegex, (match, p1) => replaceWithButtonHTML(match, p1, false))

      // Replace custom button
      description = description.replace(customButtonRegex, (match, p1) => replaceWithButtonHTML(match, p1, true))

      return description
    },
    parseConditions (description: string): string {
      const actionMap: Record<string, boolean> = {
        USER_LOGGED: this.isUserLogged,
        USER_NOT_LOGGED: !this.isUserLogged
      }

      Object.keys(actionMap).forEach(action => {
        const conditionRegex = new RegExp(`{{#if \\[${action}]}}(.*?){{\\/if}}`, 'gs')

        // Replace the conditional section with itself if the action is true, or with an empty string if it's not
        description = description.replace(conditionRegex, (match, p1) => actionMap[action] ? p1 : '')
      })

      return description
    }
  }
})
