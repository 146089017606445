export default class BookingCreateEventRequestData {
  public service_id: number
  public employee_id: number
  public start_date: string
  public start_time: string
  public service_type_id: number | null
  public required_time: number | null
  public custom_fields: string | null
  public service_location_id: number | null
  public customer_time_zone: string | null
  public customer_time_notation: string | null
  public simple_store_product_ids: number[] | null
  public user_requested_employee: boolean

  public constructor (
    serviceId: number,
    employeeId: number,
    startDate: string,
    startTime: string,
    serviceTypeId: number | null,
    requiredTime: number | null,
    customFields: string | null = null,
    serviceLocationId: number | null = null,
    customerTimeZone: string | null = null,
    customerTimeNotation: string | null = null,
    simpleStoreProductIds: number[] | null = null,
    userRequestedEmployee = false
  ) {
    this.service_id = serviceId
    this.employee_id = employeeId
    this.start_date = startDate
    this.start_time = startTime
    this.service_type_id = serviceTypeId
    this.required_time = requiredTime
    this.custom_fields = customFields
    this.service_location_id = serviceLocationId
    this.customer_time_zone = customerTimeZone
    this.customer_time_notation = customerTimeNotation
    this.simple_store_product_ids = simpleStoreProductIds
    this.user_requested_employee = userRequestedEmployee
  }
}
