import UserDefaultAddressRequestData from '@/calendesk/models/DTO/Request/UserDefaultAddressRequestData'

export default class CreateUserRequestData {
  public email: string
  public name: string | null
  public surname: string | null
  public send_reset_password = false
  public default_phone: string | null
  public default_address?: UserDefaultAddressRequestData

  public constructor (
    email: string,
    name: string | null,
    surname: string | null,
    sendResetPassword = false,
    defaultPhone: string | null = null) {
    this.email = email
    this.name = name
    this.surname = surname
    this.send_reset_password = sendResetPassword
    this.default_phone = defaultPhone
  }
}
