








































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import { ServiceLocationType } from '@/calendesk/models/DTO/Response/ServiceLocationType'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'
import { required } from '@/calendesk/forms/validators'

export default mixins(DraftElement).extend({
  name: 'CLocations',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    event: {
      type: Object,
      default: null
    },
    value: {
      type: ServiceLocation,
      default: null
    },
    locationAlwaysSelected: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedLocation: null,
      rules: {
        required
      }
    }
  },
  watch: {
    value (val) {
      this.selectedLocation = val
    },
    selectedLocation (location: ServiceLocationType | null) {
      this.$emit('change', location)
      this.$emit('input', location)
    }
  },
  created () {
    if (!this.selectedLocation && this.event && this.event.service.locations && (this.locationAlwaysSelected || this.event.service.locations.length === 1)) {
      this.selectedLocation = (this.event.service.locations && this.event.service.locations.length > 0) ? this.event.service.locations[0] : null
    }
  },
  methods: {
    getIconForType (type: ServiceLocationType) {
      switch (type) {
        case 1:
        case 4:
          return '$marker'
        case 2:
          return '$phone'
        default:
          return null
      }
    },
    getImageForType (type: ServiceLocationType) {
      switch (type) {
        case 3:
          return require('@/assets/google-meet-small-logo.png')
        case 5:
          return require('@/assets/zoom-small-logo.png')
        case 6:
          return require('@/assets/teams-logo-small.png')
        case 7:
          return require('@/assets/skype-logo-small.png')
        case 8:
          return require('@/assets/whatsapp-logo-small.png')
        default:
          return null
      }
    },
    getLabelForServiceLocation (serviceLocation: ServiceLocation): string {
      let label = `${this.$trans('service_location_' + serviceLocation.type)}`

      if (serviceLocation.data) {
        label += ` (${serviceLocation.data})`
      }

      return label
    }
  }
})
