import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import moment from 'moment'
import BookingEvent from '@/calendesk/models/BookingEvent'
import Booking from '@/calendesk/models/DTO/Response/Booking'

export default mixins(DraftElement).extend({
  methods: {
    timeZone (booking: BookingEvent): string {
      return this.isTimeZoneHidden ? this.appConfiguration.timeZone : booking.customerTimeZone || this.guessTimeZone()
    },
    getStartDate (booking: BookingEvent) {
      return moment(booking.startDate + ' ' + booking.startTime).format(DateTimeFormats.FULL)
    },
    getEndDate (booking: BookingEvent) {
      return moment(booking.endDate + ' ' + booking.endTime).format(DateTimeFormats.FULL)
    },
    displayStartDate (booking: Booking) {
      return moment(booking.startDate + ' ' + booking.startTime).format(DateTimeFormats.FULL_WITH_MONTH_TEXT)
    },
    displayEndDate (booking: Booking) {
      return moment(booking.endDate + ' ' + booking.endTime).format(DateTimeFormats.FULL_WITH_MONTH_TEXT)
    },
    getStartsAtTime (booking: BookingEvent) {
      return moment(booking.startDate + ' ' + booking.startTime).format(DateTimeFormats.TIME)
    },
    getEndsAtTime (booking: BookingEvent) {
      return moment(booking.endDate + ' ' + booking.endTime).format(DateTimeFormats.TIME)
    },
    isMultipleDays (booking: BookingEvent) {
      return moment(booking.startDate + ' ' + booking.startTime).format(DateTimeFormats.FULL) !==
        moment(booking.endDate + ' ' + booking.endTime).format(DateTimeFormats.FULL)
    }
  }
})
