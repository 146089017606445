









































































































































import CPhoneInput from '@/views/components/CPhoneInput.vue'
import parseCheckboxDescription from '@/calendesk/tools/parseCheckboxDescription'

import { BookingDynamicFormTypes } from '@/calendesk/models/BookingDynamicFormTypes'
import {
  email, equalsValue,
  lowercase,
  maxChars,
  minChars,
  number,
  required,
  specialChar,
  uppercase
} from '@/calendesk/forms/validators'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import CCountryAutocomplete from '@/components/CCountryAutocomplete.vue'
export default mixins(DraftElement).extend({
  name: 'FormElements',
  props: {
    elements: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  components: { CCountryAutocomplete, CPhoneInput },
  data () {
    return {
      formData: [] as Array<Record<string, any>>,
      addressData: [] as Array<any>,
      rules: {
        required,
        email,
        minChars,
        maxChars,
        lowercase,
        uppercase,
        number,
        specialChar,
        equalsValue
      }
    }
  },
  computed: {
    defaultCountryIsoCode (): string {
      return (this.appConfiguration.companyCountryIsoCode || this.appConfiguration.language).toLowerCase()
    }
  },
  methods: {
    previousElementWasCheckbox (index: number): boolean {
      const previousElement: any = this.elements[index - 1]

      return previousElement && previousElement.type === this.dynamicFormTypes.CHECKBOX
    },
    nextElementIsCheckbox (index: number): boolean {
      const previousElement: any = this.elements[index + 1]

      return previousElement && previousElement.type === this.dynamicFormTypes.CHECKBOX
    },
    getAddressDetailsForIndex (index: number): string {
      const street = this.addressData[index].street_and_number
      const postcode = this.addressData[index].postcode
      const city = this.addressData[index].city
      const country = this.addressData[index].country || this.defaultCountryIsoCode
      const addressValues = [street, postcode, city, country.toUpperCase()]
      const filteredValues = addressValues.filter(val => val)
      const address = filteredValues
        .map((val, i) => i === filteredValues.length - 1 ? val : val + ', ') // add comma after each value except the last value
        .join('')

      return address
    },
    updateAddressFormData (index: number, element: any, key: string, value: any) {
      if (!this.addressData[index]) {
        this.addressData[index] = []
      }

      this.addressData[index][key] = value

      this.updateDynamicFormElement(index, element, null)
    },
    updateDynamicFormElement (index: number, element: any, value: any) {
      const type = element.type
      let parsedValue = value
      if (element.type === this.dynamicFormTypes.ADDRESS) {
        parsedValue = this.getAddressDetailsForIndex(index)
      }

      this.formData[index] = {
        type: type,
        label: element.label,
        description: element.description,
        required: element.required,
        equal: element.equal,
        radioOptions: element.radioOptions,
        value: parsedValue
      }

      this.$emit('updated', this.formData.filter(value => value))
    },
    getRulesForDynamicElement (element: any, merge: Array<any> = []): Array<any> {
      const rules = []
      if (element.required) {
        rules.push(this.rules.required)
      }

      if (element.type === BookingDynamicFormTypes.TEXT_FIELD ||
        element.type === BookingDynamicFormTypes.EMAIL) {
        rules.push(this.rules.maxChars(255))
      } else if (element.type === BookingDynamicFormTypes.TEXT_AREA) {
        rules.push(this.rules.maxChars(5000))
      }

      if (element.type === BookingDynamicFormTypes.EMAIL) {
        rules.push(this.rules.email)
      }

      if (element.equal) {
        rules.push(this.rules.equalsValue(element.equal))
      }

      return rules.concat(merge)
    },
    parseCheckboxDescription
  }
})
