import CreateUserRequestData from '@/calendesk/models/DTO/Request/CreateUserRequestData'
import BookingCreateEventRequestData from '@/calendesk/models/DTO/Request/BookingCreateEventRequestData'

export default class BookingCreateRequestData {
  public user: CreateUserRequestData
  public bookings: Array<BookingCreateEventRequestData>

  public constructor (
    user: CreateUserRequestData,
    bookings: Array<BookingCreateEventRequestData>) {
    this.user = user
    this.bookings = bookings
  }
}
